import React, { FC } from 'react';
import { graphql } from 'gatsby';

import Layout from 'components/Layout';
import Banner from 'components/Banner';
import RelatedProducts from 'components/RelatedProducts';
import Callout from 'components/common/Callout';
import ArticleListFilter from 'components/ArticleListFilter';
import { parseBanner } from 'components/Banner/parsers';
import { parseNFButton } from 'components/common/NFButton/parsers';
import { parseArticlesFilters } from 'components/ArticleListFilter/parsers';
import { getPageIdFromUmbracoId } from 'utils/helpers';
import parseCalloutSection from 'templates/AdvancedChildrenHubArticlePage/parsers';

import { IArticlePreviewComponentProps as IKidsHubArticlePreview } from 'components/ArticlePreview/model';

import { IArticleListingPage } from './models';

import './ArticleListingPage.scss';

const ArticleListingPage: FC<{
  pageContext: {
    breadcrumbs: UmbracoTypes.IBreadCrumb[];
    articlesLinks: string[];
    relatedProducts: string[];
  };
  data: IArticleListingPage;
}> = ({
  pageContext: { breadcrumbs, articlesLinks, relatedProducts: relatedProductsLinks },
  data: {
    defaultPage,
    defaultPageLangs,
    unbrandedPage,
    unbrandedPageLangs,
    defaultArticles,
    unbrandedArticles,
    kidsHubArticles,
    relatedProducts,
    articleFilters,
    articleListConfigs,
    articleUnbrandedListConfigs,
    carouselControls,
    header,
    footer,
    siteSettings,
    covidBanner,
    newsletterPopupDefault,
    newsletterPopupDiscount,
    newsletterPopupFreeDelivery,
    disclaimerPopup,
    searchPath,
    legalPopup,
    brandSettings,
  },
}) => {
  const isUnbrandedType = Boolean(unbrandedPage);
  const page = defaultPage || unbrandedPage;
  const lang = defaultPage?.lang || unbrandedPage?.lang;
  const pageLangs = isUnbrandedType ? unbrandedPageLangs : defaultPageLangs;

  let articles: Array<ArticleListingTypes.IArticleCard | IKidsHubArticlePreview>;
  articles = isUnbrandedType ? unbrandedArticles.nodes : defaultArticles.nodes;
  if (page?.isArticleListingPartOfKidsHub) {
    articles = kidsHubArticles.nodes.map(
      ({ title, link, tags, fullWidthBanner, isVideo, description }) => ({
        title,
        link,
        tags,
        previewImage: fullWidthBanner[0]!.properties,
        isVideo,
        description,
      })
    );
  }

  const listConfigs = isUnbrandedType ? articleUnbrandedListConfigs : articleListConfigs;

  const articleFIltersProperties = page.articleListingPageFilterArticleList?.[0]?.properties;
  const showFilters = articleFIltersProperties?.showArticlesFiltersSidebar === '1';
  const isFixedFilters = Boolean(Number(articleFIltersProperties?.isFixedFilters));
  const counterFirstWord = articleFIltersProperties?.counterFirstWord;
  const counterMiddleWord = articleFIltersProperties?.counterMiddleWord;
  const filtersTitle = articleFIltersProperties?.filtersTitle;
  const isArticlesCounterDisplayed = Boolean(
    Number(articleFIltersProperties?.isArticlesCounterDisplayed)
  );

  const relatedProductsProps = page?.relatedProductsConfig?.[0]?.properties;

  const parsedCalloutSection = page?.calloutSection?.length
    ? parseCalloutSection({ ...page.calloutSection[0].properties })
    : null;

  const pageArticleFilters = page?.isArticleListingPartOfKidsHub
    ? {
        ...articleFilters,
        masterPageId: getPageIdFromUmbracoId(page.umbracoId),
        masterPageLink: page.link,
      }
    : articleFilters;

  return (
    <Layout
      seo={{
        seoMetaTitle: page.seoMetaTitle,
        seoMetaDescription: page.seoMetaDescription,
        seoMetaKeywords: page.seoMetaKeywords,
        seoExternalHreflangs: page.seoExternalHreflangs,
        ogImage: page.ogImage,
      }}
      className="article-listing-page"
      header={header}
      footer={footer}
      siteSettings={siteSettings}
      covidBanner={covidBanner}
      newsletterPopupDefault={newsletterPopupDefault}
      newsletterPopupDiscount={newsletterPopupDiscount}
      newsletterPopupFreeDelivery={newsletterPopupFreeDelivery}
      disclaimerPopup={disclaimerPopup}
      searchPath={searchPath}
      brandSettings={brandSettings}
      legalPopup={legalPopup}
      langSettings={{
        currentLang: lang,
        langs: pageLangs.nodes,
      }}
    >
      <Banner {...parseBanner(page.articleListingBanner[0].properties)} breadcrumbs={breadcrumbs} />

      <ArticleListFilter
        {...parseArticlesFilters(
          {
            ...pageArticleFilters,
            filterSections: page?.articleListingPageFilterArticleList?.[0]?.properties
              ? page.articleListingPageFilterArticleList[0].properties
                  .filterArticleListFilterSections
              : null,
          },
          showFilters,
          isFixedFilters,
          counterFirstWord,
          counterMiddleWord,
          page.umbracoId,
          listConfigs,
          articles,
          page.showManuallySelectedArticles,
          articlesLinks,
          page.lang,
          isUnbrandedType,
          page?.isArticleListingPartOfKidsHub,
          isArticlesCounterDisplayed,
          filtersTitle
        )}
        bgSectionColor="grey"
      />

      {parsedCalloutSection ? (
        <Callout
          title={parsedCalloutSection.callout.title}
          description={parsedCalloutSection.callout.description}
          calloutColor={parsedCalloutSection.callout.color}
          tagsLists={parsedCalloutSection.tagsLists}
          featuredArticles={parsedCalloutSection?.featuredArticles}
          settings={{ carouselControls }}
          featuredArticlesCTAButton={parsedCalloutSection?.featuredArticlesCTAButton}
        />
      ) : null}

      {relatedProducts?.nodes?.length ? (
        <RelatedProducts
          title={relatedProductsProps?.relatedProductsTitle}
          showBtn={relatedProductsProps?.shopBtnsShow === '1'}
          btn={
            relatedProductsProps?.sectionCtaBtn?.length
              ? parseNFButton(relatedProductsProps?.sectionCtaBtn?.[0]?.properties)
              : undefined
          }
          productsLinks={relatedProductsLinks}
          products={relatedProducts?.nodes}
          carouselControls={carouselControls}
          listingName={page.pageName}
          reverseCardItems={relatedProductsProps?.reverseCardItems}
        />
      ) : null}
    </Layout>
  );
};

export const query = graphql`
  fragment FragmentArticleListingPage on IUmbracoArticleListingBase {
    ...FragmentSeo
    articleListingBanner {
      properties {
        ...FragmentBanner
      }
    }
    relatedProductsConfig {
      properties {
        ...FragmentRelatedProductsConfig
      }
    }
    articleListingPageFilterArticleList {
      properties {
        ...FragmentFilterArticleList
      }
    }
    umbracoId
    lang
    link
    pageName
    showManuallySelectedArticles
    articlesLinks
    isArticleListingPartOfKidsHub
    calloutSection {
      properties {
        ...FragmentAdvancedChildrenHubCalloutSection
      }
    }
  }

  query(
    $lang: String!
    $link: String!
    $articlesLinks: [String]
    $relatedProducts: [String]
    $pageBannerImageSize: Int = 1000
    $pageIdRegex: String
  ) {
    defaultPage: umbracoArticleListing(link: { eq: $link }, lang: { eq: $lang }) {
      ...FragmentArticleListingPage
    }
    defaultPageLangs: allUmbracoArticleListing(filter: { umbracoId: { regex: $pageIdRegex } }) {
      nodes {
        link
        lang
      }
    }
    unbrandedPage: umbracoUnbrandedArticleListing(link: { eq: $link }, lang: { eq: $lang }) {
      ...FragmentArticleListingPage
    }
    unbrandedPageLangs: allUmbracoUnbrandedArticleListing(
      filter: { umbracoId: { regex: $pageIdRegex } }
    ) {
      nodes {
        link
        lang
      }
    }
    defaultArticles: allUmbracoArticles(
      filter: { link: { in: $articlesLinks }, lang: { eq: $lang } }
    ) {
      nodes {
        ...FragmentFeaturedArticleData
      }
    }
    unbrandedArticles: allUmbracoUnbrandedArticle(
      filter: { link: { in: $articlesLinks }, lang: { eq: $lang } }
    ) {
      nodes {
        ...FragmentFeaturedArticleData
      }
    }
    kidsHubArticles: allAdvancedChildrenHubArticlePage(
      filter: { link: { in: $articlesLinks }, lang: { eq: $lang } }
    ) {
      nodes {
        ...FragmentArticlePreviewCardFromArticlePage
      }
    }
    relatedProducts: allUmbracoProduct(
      filter: { link: { in: $relatedProducts }, lang: { eq: $lang } }
      limit: 6
    ) {
      nodes {
        ...FragmentProductCard
      }
    }
    carouselControls: siteSettings(lang: { eq: $lang }) {
      ariaLabelNext
      ariaLabelPrev
    }
    articleFilters(lang: { eq: $lang }) {
      ...FragmentArticlesFilters
    }
    articleListConfigs(lang: { eq: $lang }) {
      ...FragmentArticleListConfigs
    }
    articleUnbrandedListConfigs(lang: { eq: $lang }) {
      ...FragmentArticleListConfigs
    }
    ...FragmentCommonCompositions
  }
`;

export default ArticleListingPage;
